.dashboard{
    font-family: 'Poppins', sans-serif;
    position: relative;
    .home{
        line-height: 48px;
    }

    .bdr{
        border: 1px solid #CCE1FF;
        padding: 7px 15px;
        border-radius: 6px;
    }

    .no-bdr{
        border: none;
    }
    .card-bdr{
        border: 1px solid #EDEDF2;
        border-radius: 6px;
    }

    .header{
        justify-content: space-between;
    }

    .title{
        font-size: 16px;
    }

    .batch{
        border: 1px solid #0055CC;
        color: #0055CC;
        padding: 5px 6px;
        border-radius: 4px;
        font-size: 12px;
    }

    .lorem{
        color: #6A6E83;
        font-size: 11px;
        line-height: 16px;
    }

    .img{
        position: fixed;
        bottom: 10px;
        right: 10px;
        cursor: pointer;
    }
    
.chat-box {
    // display:none;
    background: #efefef;
    position:fixed;
    right:30px;
    bottom:50px;  
    width:350px;
    max-width: 85vw;
    max-height:100vh;
    border-radius:5px;  
  /*   box-shadow: 0px 5px 35px 9px #464a92; */
    box-shadow: 0px 5px 35px 9px #ccc;
  }
  .chat-box-toggle {
    float:right;
    margin-right:15px;
    cursor:pointer;
  }
  .chat-box-toggle:hover {
    transform: rotate(90deg);
    transition: 0.5s all;
  }
  .chat-box-header {
    background: rgba(0, 42, 100, 1);
    height:42px;
    // border-radius: 10px 10px 0px 0px;
    border-radius: 10px 10px 0px 0px;
    color:white;
    text-align:left;
    padding:9px;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: italic;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;

  }
  .chat-box-body {
    position: relative;  
    height:370px;  
    height:auto;
    border:1px solid #ccc;  
    overflow: hidden;
    border-radius:0px 0px 10px 10px ;
  }
  .chat-box-body:after {
    content: "";
    opacity: 0.1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height:100%;
    position: absolute;
    z-index: -1;   
  }
  #chat-input {
    background: #fff;
    width:100%; 
    position:relative;
    height:47px;  
    padding-top:10px;
    padding-right:50px;
    padding-bottom:10px;
    padding-left:15px;
    border:none;
    resize:none;
    outline:none;
    border:1px solid #ccc;
    color:#888;
    border-top:none;
    border-bottom-right-radius:5px;
    border-bottom-left-radius:5px;
    overflow:hidden;  
  }
  .chat-input > form {
      margin-bottom: 0;
  }
  #chat-input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #ccc;
  }
  #chat-input::-moz-placeholder { /* Firefox 19+ */
    color: #ccc;
  }
  #chat-input:-ms-input-placeholder { /* IE 10+ */
    color: #ccc;
  }
  #chat-input:-moz-placeholder { /* Firefox 18- */
    color: #ccc;
  }
  .chat-submit {  
    position:absolute;
    bottom:3px;
    right:10px;
    background: transparent;
    box-shadow:none;
    border:none;
    border-radius:50%;
    color:#5A5EB9;
    width:35px;
    height:35px;  
  }
  .chat-logs {
    padding:15px; 
    height:430px;
    // height: 328px;
    overflow-y:auto;
    background: #fff;
    // border-radius:0px 0px 10px 10px ;

    h3 {
        font-family: 'Open Sans';
        font-size: 16px;
        font-weight: 600;
        color: #2A295C;
    }
    p {font-family: 'Open Sans';
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        }
  }
  
  .chat-logs::-webkit-scrollbar-track
  {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;
  }
  
  .chat-logs::-webkit-scrollbar
  {
      width: 5px;  
      background-color: #F5F5F5;
  }
  
  .chat-logs::-webkit-scrollbar-thumb
  {
      background-color: #5A5EB9;
  }
  
  @media only screen and (max-width: 500px) {
     .chat-logs {
          height:40vh;
      }
  }
  
  .chat-msg.user > .msg-avatar img {
    width:45px;
    height:45px;
    border-radius:50%;
    float:left;
    width:15%;
  }
  .chat-msg.self > .msg-avatar img {
    width:45px;
    height:45px;
    border-radius:50%;
    float:right;
    width:15%;
  }
  .cm-msg-text {
    background:white;
    padding:10px 15px 10px 15px;  
    color:#666;
    max-width:75%;
    float:left;
    margin-left:10px; 
    position:relative;
    margin-bottom:20px;
    border-radius:30px;
  }
  .chat-msg {
    clear:both;    
  }
  .chat-msg.self > .cm-msg-text {  
    float:right;
    margin-right:10px;
    background: #5A5EB9;
    color:white;
  }
  .cm-msg-button>ul>li {
    list-style:none;
    float:left;
    width:50%;
  }
  .cm-msg-button {
      clear: both;
      margin-bottom: 70px;
  }
  .btn_sec {
    width: max-content;
    margin: auto;
  }
  .btn_cstm {
    // border: 1px solid #2A295C;
    border: 1px solid #E7E9F7;
    border-radius: 50rem ;
    padding: 7px 20px;
    font-size: 13px;
    color: #2A295C;
    background: #fff;

    :hover {
        color: #fff;
        background: #2A295C;
    }
}
}
